import { useParams } from "react-router-dom";
import CustomerDetails from "../components/ApplicationEdit/CustomerDetails";
import { useEffect, useState } from "react";
import ApplicationService from "../services/application";
import LoanDetails from "../components/ApplicationEdit/LoanDetails";
import Breadcrumbs from "../components/Breadcrumbs";

const ApplicationEdit = ({ showSnackbar }) => {

  const [details, setDetails] = useState({})
  const [application_id, setApplicationId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();
  const id = params.id;

  useEffect(() => {
    setIsLoading(true);
    ApplicationService.getApplication(id).then((res) => {
      setApplicationId(id);
      setDetails(res.data.data);
      setIsLoading(false);
    })
  }, [id])

  const handleDetailChange = (name, value, key) => {
    
    let _details = {...details};
    
    let selected_key_obj = _details[key] || {}
    let selected_obj = {
      ...selected_key_obj,
      [name] : value
    };

    _details[key] = selected_obj;
    setDetails(_details);
  }

  return(
    <div className="site-container application-edit-container">
      
      <Breadcrumbs
        links = {[
          { title : "Home", to : "/" },
          { title : "Application" },
          { title : "Edit" },
          { title : application_id, to : `/application/group/${application_id}` }
        ]}
      />

      <div className={`loader-container d-flex align-items-center justify-content-center ${isLoading ? "show" : ""}`}>
        <div className="loader"></div>
      </div>

      {
        !isLoading &&
        <>
          <CustomerDetails
            details = {details.customer_details}
            value_field = "customer_details"
            handleDetailChange = {handleDetailChange}
            application_id = {application_id}
            showSnackbar = {showSnackbar}
          />
          <LoanDetails
            details = {details.loan_details}
            value_field = "loan_details"
            handleDetailChange = {handleDetailChange}
            application_id = {application_id}
            showSnackbar = {showSnackbar}
          />
        </>
      }
    </div>
  )
}

export default ApplicationEdit;