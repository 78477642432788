import { useEffect, useState } from "react";
import CreateAgentPopup from "../components/CreateAgentPopup";
import AuthService from "../services/auth";
import Pagination from "../components/Pagination";
import { ReactComponent as Edit } from "../static/icons/edit-icon.svg";
import Branches from "../data/Branches.json";
import Breadcrumbs from "../components/Breadcrumbs";

const Agents = () => {

  const [showCreateUser, setShowCreateUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isEdit, setEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const getUsers = () => {
    AuthService.getUsers(page).then((res) => {
      setUsers(res.data.data);
      setCount(res.data.total_records);
    })
  }

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [page])

  const handleClosePopup = () => {
    setShowCreateUser(false);
    setEdit(false);
    setSelectedUser({});
  }

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setEdit(true);
    setShowCreateUser(true);
  }

  const getBranch = (code) => {
    const selectedBranch = Branches.find((branch) => branch.branch_id === code);
    return selectedBranch.bank_branch_name
  }

  return (
    <div className="agent-container site-container">

      <div className="home-container">
      
     {/*  <Breadcrumbs
        links = {[
          { title : "Home", to : "/" },
          { title : "Agents" }
        ]}
      /> */}

        <div className="paper with-search mb-3 d-flex align-items-center justify-content-between">

            <div className="page-title">AGENTS</div>
            <button className="create-new-btn" onClick={() => setShowCreateUser(true)}>
              Create New
            </button>
            <CreateAgentPopup
                show = {showCreateUser}
                handleClose = {handleClosePopup}
                getUsers = {getUsers}
                isEdit = {isEdit}
                selectedUser = {selectedUser}
              />
         
        </div>

     

     

      <div className="paper with-table">
        <table className="table w-100">
          <thead>
            <tr>
              <th> Sr No </th>
              <th> Name </th>
              <th> Email </th>
              <th> Contact No </th>
              <th> Branch </th>
              {/* <th> Region </th> */}
              <th className="text-center"> Edit </th>
            </tr>
          </thead>

          <tbody>
            {
              users.map((user,ind) => 
                <tr key={user.uid}>
                  <td> {ind + 1} </td>
                  <td> {user.name} </td>
                  <td> {user.email} </td>
                  <td> {user.contact_no} </td>
                  <td> {getBranch(user.branch)} </td>
                  {/* <td> {user.region} </td> */}
                  <td className="d-flex justify-content-center">
                    <Edit
                      className="cursor-pointer"
                      height={24}
                      onClick = {() => handleEditUser(user)}
                    />
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>

      <Pagination
        count={count}
        page={page}
        handleChangePage={(pageNo) => setPage(pageNo)}
      />
    
    </div>
    </div>
  )
}

export default Agents;