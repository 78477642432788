import PopupWrapper from "./PopupWrapper";
import { ReactComponent as Close } from "../static/icons/cross-icon.svg";
import { useEffect, useState } from "react";
import AuthService from "../services/auth";
import validator from "validator";
import Branches from "../data/Branches.json";

const CreateAgentPopup = ({ show, handleClose, getUsers, isEdit, selectedUser }) => {

  const blankDetailsObj = {
    name : "",
    email : "",
    password : "",
    contact_no : "",
    branch : "",
    role : "agent"
  }
  const [details, setDetails] = useState(blankDetailsObj);

  const [isSaving, setIsSaving] = useState(false)

  const handleDetailChange = (e) => {
    const {name, value} = e.target
    setDetails({
      ...details,
      [name] : value
    })
  }

  const handleSave = () => {
    setIsSaving(true);
    if(isEdit){
      const updateData = {
        name : details.name,
        branch : details.branch,
        contact_no : details.contact_no
      }
      AuthService.updateUser(selectedUser.uid, updateData).then(() => {
        closePopup();
      })
    }else{
      AuthService.createUser(details).then(() => {
        closePopup();
      })
    }
  }
  
  const closePopup = () => {
    setIsSaving(false);
    handleClosePopup();
    getUsers();
  }

  const handleClosePopup = () => {
    handleClose();
    setDetails(blankDetailsObj);
  }

  useEffect(() => {
    if(isEdit){
      setDetails({
        ...selectedUser
      })
    }
  }, [isEdit, selectedUser]);

  const isValidData = () => {
    if(isEdit){
      if(
        details.contact_no !== "" &&
        details.branch !== ""
      ){
        return true
      }
    }else{
      if(
        validator.isEmail(details.email) &&
        details.contact_no !== "" &&
        details.branch !== "" &&
        details.password.length > 0
      ){
        return true
      }
    }
    return false;
  }

  return(
    <PopupWrapper show = {show}>
      <div className="create-agent-popup popup">
        
        <div className="popup-title d-flex align-items-center justify-content-between">
          {isEdit ? "Edit" : "Create"} User

          <Close height={28} className = "cursor-pointer" onClick={handleClosePopup} />
        </div>

        <div className="input-container">
          <label> Full Name </label>
          <input
            type="text"
            className="w-100"
            name = "name"
            value={details.name}
            onChange={handleDetailChange}
          />
        </div>
        {
          !isEdit &&
          <>
            <div className="input-container">
              <label> Email </label>
              <input
                type="text"
                className="w-100"
                name = "email"
                value={details.email}
                onChange={handleDetailChange}
              />
            </div>
            <div className="input-container">
              <label> Password </label>
              <input
                type="password"
                className="w-100"
                name = "password"
                onChange={handleDetailChange}
                value={details.password}
              />
            </div>
          </>
        }
        <div className="input-container">
          <label> Contact Number </label>
          <input
            type="text"
            className="w-100"
            name = "contact_no"
            value={details.contact_no}
            onChange={handleDetailChange}
          />
        </div>
        <div className="input-container">
          <label> Branch </label>
          <select
            className="w-100"
            name = "branch"
            value={details.branch}
            onChange={handleDetailChange}
          >
            {
              Branches.map((branch) =>
                <option value={branch.branch_id} key={branch.branch_id}> {branch.bank_branch_name} </option>
              )
            }
          </select>
        </div>

        <div className="d-flex justify-content-end">
          <button
            disabled = {
              !isValidData() ||
              isSaving
            }

            onClick = {handleSave}
          >
            {isSaving ? "Saving . . ." : "Save"}
          </button>
        </div>


      </div>
    </PopupWrapper>
  )
}

export default CreateAgentPopup;