import { useState } from "react";
import { allowOnlyNumbers } from "../../helpers";
import validator from "validator";
import moment from "moment";
import ApplicationService from "../../services/application";
import { messageTypes } from "../../constants";
import DatePicker from "react-datepicker";
import CustomDateInput from "../CustomDateInput";

const CustomerDetails = ({
  details,
  value_field,
  handleDetailChange,
  application_id,
  showSnackbar
}) => {
  
  const [isSaving, setIsSaving] = useState(false);

  const isDataFilled = () => {
    if(
      details.cif_number !== "" &&
      details.customer_name !== "" &&
      details.civil_id !== "" &&
      details.dob !== "" &&
      details.gender !== "" &&
      details.contact_number !== "" &&
      details.email !== "" &&
      details.height !== "" &&
      details.weight !== ""
    ){
      return true;
    }
    return false
  }

  const [errors, setErrors] = useState([])

  const getBmi = () => {
    const height_in_cm = parseFloat(details.height);
    const weight = parseFloat(details.weight);
    const height_in_m = parseFloat(Number(height_in_cm / 100).toFixed(2));

    const bmi = parseFloat(Number(weight / (height_in_m * height_in_m)).toFixed(2));
    return bmi;
  }

  const handleClickSave = () => {
    let errorsArr = [];
    if(details.cif_number.length < 7){
      errorsArr.push("cif_number")
    }
    if(!validator.matches(details.customer_name, /^[a-zA-Z ]*$/)){
      errorsArr.push("customer_name")
    }
    if(details.contact_number.length < 8){
      errorsArr.push("contact_number")
    }
    if(details.height.length < 3 || parseInt(details.height) === 0){
      errorsArr.push("height")
    }
    if(details.weight.length < 2 || parseInt(details.weight) === 0){
      errorsArr.push("weight")
    }
    if(!validator.isEmail(details.email)){
      errorsArr.push("email")
    }
    setErrors(errorsArr);
    if(errorsArr.length === 0){
      
      setIsSaving(true);

      const update_data = {
        [value_field] : {
          ...details,
          age : parseFloat(Number(moment().diff(details.dob, "years", true)).toFixed(2))
        },
        bmi : getBmi()
      }
      ApplicationService.updateApplication(application_id, update_data, "").then((res) => {
        setIsSaving(false);
        showSnackbar("Customer details updated successfully !", messageTypes.SUCCESS)
      }).catch(() => {
        setIsSaving(false);
        showSnackbar("Customer details not updated !", messageTypes.ERROR)
      })
    }
  }

  const showError = errors.length !== 0;

  return(
    <div className="paper">
      <div className="paper-title">
        Customer Details
      </div>

      <div className="grid-row">
        <div className="input-container">
          <label> Customer ID </label>
          <input
            type="text"
            className={`w-100 ${showError && errors.includes("cif_number") ? "has-error" : ""}`}
            name = "cif_number"
            value={details.cif_number}
            onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
            onKeyDown={(e) => allowOnlyNumbers(e, 7)}
          />
          {
            (showError && errors.includes("cif_number")) &&
            <div className="error-message"> Please enter 7 digit Customer ID </div>
          }
        </div>

        <div className="input-container">
          <label> Customer Name (As per national ID) </label>
          <input
            type="text"
            className={`w-100 ${showError && errors.includes("customer_name") ? "has-error" : ""}`}
            name = "customer_name"
            value={details.customer_name}
            onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
          />
          {
            (showError && errors.includes("customer_name")) &&
            <div className="error-message"> Customer Name should accept letters only </div>
          }
        </div>

        <div className="input-container">
          <label> National ID </label>
          <input
            type="text"
            className="w-100"
            name = "civil_id"
            value={details.civil_id}
            onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
            onKeyDown={(e) => allowOnlyNumbers(e)}
          />
        </div>

        <div className="input-container">
          <label> Date of Birth </label>

          <DatePicker
            selected={new Date(details.dob)}
            onChange={(date) => handleDetailChange("dob", date, value_field)}
            showMonthDropdown
            showYearDropdown
            dateFormat="dd-MM-yyyy"
            customInput={<CustomDateInput />}
            maxDate={new Date(moment().subtract(18, "years").toISOString())}
            minDate={new Date(moment().subtract(65, "years").toISOString())}
            dropdownMode="select"
            placeholderText="DD-MM-YYYY"
          />
        </div>

        <div className="input-container">
          <label> Gender </label>
          <select
            className="w-100" 
            name="gender"
            value={details.gender}
            onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
          >
            <option value="" disabled> Select Gender </option>
            <option value="male"> Male </option>
            <option value="female"> Female </option>
          </select>
        </div>


        <div className="input-container">
          <label> Mobile Number </label>
          <input
            type="text"
            className={`w-100 ${showError && errors.includes("contact_number") ? "has-error" : ""}`}
            name = "contact_number"
            value={details.contact_number}
            onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
            onKeyDown={(e) => allowOnlyNumbers(e, 8)}
          />

          {
            (showError && errors.includes("contact_number")) &&
            <div className="error-message"> Please enter 8 digit Mobile Number </div>
          }
        </div>

        <div className="input-container">
          <label> Email </label>
          <input
            type="text"
            className={`w-100 ${showError && errors.includes("email") ? "has-error" : ""}`}
            name = "email"
            value={details.email}
            onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
          />
          {
            (showError && errors.includes("email")) &&
            <div className="error-message"> Please enter valid email </div>
          }
        </div>

        <div className="input-container">
          <label> Height (in cms) </label>
          <input
            type="text"
            className={`w-100 ${showError && errors.includes("height") ? "has-error" : ""}`}
            name = "height"
            value={details.height}
            onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
            onKeyDown={(e) => allowOnlyNumbers(e, 3)}
          />
          {
            (showError && errors.includes("height")) &&
            <div className="error-message"> Please enter appropriate height (minimum 3 digits) </div>
          }
        </div>

        <div className="input-container">
          <label> Weight (in kgs) </label>
          <input
            type="text"
            className={`w-100 ${showError && errors.includes("weight") ? "has-error" : ""}`}
            name = "weight"
            value={details.weight}
            onChange={(e) => handleDetailChange(e.target.name, e.target.value, value_field)}
            onKeyDown={(e) => allowOnlyNumbers(e, 3)}
          />
          {
            (showError && errors.includes("weight")) &&
            <div className="error-message"> Please enter appropriate weight (minimum 2 digits) </div>
          }
        </div>
        
        <div className="d-flex full-width justify-content-end">
          <button
            onClick = {handleClickSave}
            disabled = {!isDataFilled() || isSaving}
          >
            {isSaving ? "Saving . . ." : "Save"}
          </button>
        </div>

      </div>


    </div>
  )
}

export default CustomerDetails;