import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApplicationService from "../services/application";
import moment from "moment";
import { ReactComponent as Eye } from "../static/icons/eye-icon.svg";
import { ReactComponent as Edit } from "../static/icons/edit-icon.svg";
import Pagination from "../components/Pagination";

let timeout = undefined;

const Home = () => {

  const [applications, setApplications] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  
  useEffect(() => {
    ApplicationService.getApplications(page, 10, search).then((res) => {
      setApplications(res.data.data);
      setCount(res.data.total_records)
    }).catch(() => {})
  }, [page, search])

  const handleChangeSearch = (e) => {
    const value = e.target.value
    if(timeout){
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setSearch(value);
    }, 2000)

    setSearchValue(value);
  }

  return(
    <div className="site-container">
    <div className="home-container">

           <div className="paper with-search mb-3 d-flex align-items-center justify-content-between">
            <div className="page-title align-item-bottom">APPLICATIONS</div>
            <input
              type="text"
              className="ms-3"
              placeholder="Search by Customer ID / National ID / Customer name / Customer Email"
              onChange={handleChangeSearch}
              value={searchValue}
            />
          </div>
          
          <div className="paper with-table">
            <table className="table">
              <thead>
                <tr>
                  <th> Application # </th>
                  <th> National ID # </th>
                  <th> Customer Name </th>
                  <th> Branch Name </th>
                  <th> Type </th>
                  <th> Created Date </th>
                  <th className="text-center"> Edit </th>
                  <th className="text-center"> View </th>
                </tr>
              </thead>
              <tbody className="text-uppercase">
                {
                  applications.map((application) => 
                    <tr key={application._id} className = {`${application.is_viewed_by_underwriter ? "" : "not-attended bold"}`}>
                      <td> {application.id} </td>
                      <td> {application.customer_details.civil_id} </td>
                      <td> {application.customer_details.customer_name} </td>
                      <td> {application.branch_details.bank_branch_name} </td>
                      <td> {application.quote_type} </td>
                      <td> {moment(application.created_at).format("DD-MM-YYYY")} </td>
                      <td className="text-center">
                        <Link to={`/application/edit/${application.id}`} className = "d-inline-block">
                          <Edit className="view-icon" />
                        </Link>
                      </td>
                      <td className="text-center">
                        <Link to={`/application/${application.quote_type === "individual" ? "individual" : "group"}/${application.id}`} className = "d-inline-block">
                          <Eye className="view-icon" />
                        </Link>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>

          <Pagination
            count = {count}
            page = {page}
            handleChangePage = {(pageNo) => setPage(pageNo)}
          />
       </div>
    </div>
  )
}

export default Home;