import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ApplicationService from "../services/application";
import _ from "lodash";
import UnderwritingDetails from "../components/UnderwritingDetails";
import MedicalPopup from "../components/MedicalPopup";
import { LOAN_TYPES, medicalTests, messageTypes } from "../constants";
import MedicalCenters from "../data/MedicalCenters.json";
import DownloadDocuments from "../components/DownloadDocuments";
import { PDFDownloadLink } from "@react-pdf/renderer";
import UnderwritingSheet from "../components/UnderwritingSheet";
import DownloadService from "../services/download";
import { saveAs } from "file-saver";
import PreviousLoans from "../components/PreviousLoans";
import Breadcrumbs from "../components/Breadcrumbs";
import { ReactComponent as Edit } from "../static/icons/edit-icon.svg";

const ApplicationView = ({ showSnackbar }) => {

  const [data, setData] = useState({});
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [comment, setComment] = useState("");
  const [isSavingComment, setIsSavingComment] = useState(false);
  const [isDownloadingMtrf, setIsDownloadingMtrf] = useState(false);

  const params = useParams();
  const id = params.id;

  const getData = () => {
    ApplicationService.getApplication(id).then((res) => {
      const data = res.data.data;

      const medical_center_code = data.sent_to_medical_center ? data.confirmed_medical_center_details.medical_center : data.medical_details.desired_medical_center

      const selected_center = [...MedicalCenters].find((center) => center.code === medical_center_code);
      data.selected_center = selected_center || {};

      setData(data);
      setDataLoaded(true);
      setComment(data.underwriting_comments || "");
      if(!data.is_viewed_by_underwriter){
        ApplicationService.updateApplication(id, {}, "/update-underwriter")
      }
    })
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [])

  const Details = ({title, field, suffix = "", isDate, dateFormat, prefix = "", isAmount = false}) => {
    let value = _.get(data, field);
    if(isDate){
      value = moment(value).isValid() ? moment(value).format(dateFormat) : ""
    }
    if(isAmount){
      value = Number(value).toLocaleString()
    }
    return(
      <div className="detail-container d-flex align-items-center">
        <div className="title">
          {title}
        </div>
        <div className="value flex-grow-1">
          {prefix} {value} {suffix}
        </div>
      </div>
    )
  }

  const handleSaveComment = () => {
    setIsSavingComment(true);
    ApplicationService.updateApplication(id, {underwriting_comments : comment}).then(() => {
      showSnackbar("Comments Saved !", messageTypes.SUCCESS)
      setIsSavingComment(false);
    })
  }

  const getPreunderwritingStatus = () => {
    const { customer_details, bmi, loan_details } = data;

    const loan_amount = parseFloat(loan_details.total_loan_exposure_amount);
    const age = customer_details.age;

    let medicalReuirements = [];
    // if(age >= 50 && loan_amount <= 400000){
    //   medicalReuirements = [medicalTests.MER, medicalTests.LAB_1]
    // }
    // if(age >= 50 && loan_amount > 400000 && loan_amount <= 800000){
    //   medicalReuirements = [medicalTests.MER, medicalTests.LAB_1, medicalTests.ECG]
    // }
    // if(loan_amount > 800000 && loan_amount <= 2000000){
    //   medicalReuirements = [medicalTests.MER, medicalTests.LAB_2, medicalTests.ECG]
    // }
    if(loan_amount > 2000000 && loan_amount <= 4000000){
      medicalReuirements = [medicalTests.MER, medicalTests.URINE_ANALYSIS]
    }

    let status = "Standard - Non Medical"
    if(
      medicalReuirements.length ||
      (bmi <= 17 || bmi > 36)
    ) {
      status = "Substandard - Medical Required"
    }

    return status;
  }

  const downloadMtrf = () => {
    setIsDownloadingMtrf(true);
    DownloadService.downloadDocument("mtrf", data.id).then((res) => {
      saveAs(res.data, `${data.id}-MTRF.pdf`);
      setIsDownloadingMtrf(false);
    })
  }

  return(
    <>
      <div className={`loader-container d-flex align-items-center justify-content-center ${isDataLoaded ? "" : "show"}`}>
        <div className="loader"></div>
      </div>
      {
        isDataLoaded &&
        <div className="site-container">

          <Breadcrumbs
            links = {[
              { title : "Home", to : "/" },
              { title : "Application" },
              { title : "View" },
              { title : id }
            ]}
          />


          <div className="application-details-container">
            <div>
              <div className="paper underwriting-sheet">
                <div className="paper-title text-center">
                  Underwriting Sheet
                </div>

                <div className="detail-row mb-2">
                  <div>
                    <Details title = "Application No" field="id" />
                    <Details title = "Branch name" field="branch_details.bank_branch_name" />
                    <Details title = "BMI" field="bmi" />
                    <Details title = "Age" field="customer_details.age" />
                  </div>
                  <div>
                    <Details title = "Occupation Type" field="employment_details.occupation_type" />
                    <Details title = "Employer Name" field="employment_details.employer_name" />
                    <Details title = "Exact Nature of Duties" field="employment_details.exact_nature_of_duties" />
                  </div>
                </div>
                <Details title = "Customer Name" field="customer_details.customer_name" />
                
                <div className="detail-row">
                  <div>
                    <Details title = "Customer ID" field="customer_details.cif_number" />
                    <Details title = "Date of Birth" field="customer_details.dob" isDate dateFormat="DD-MM-YYYY" />
                    <Details title = "Gender" field="customer_details.gender" />
                    <Details title = "Height" field="customer_details.height" suffix="cms" />
                    <Details title = "Weight" field="customer_details.weight" suffix="kgs" />
                  </div>

                  <div>
                    <Details title = "Loan Type" field="loan_details.loan_type" />
                    <Details title = "New Loan Amount" field="loan_details.new_loan_amount" prefix="AED" isAmount />
                    <Details title = "Existing Loan Amount" field="loan_details.existing_loan_amount" prefix="AED" isAmount />
                    <Details title = "Loan Period" field="loan_details.loan_tenure" suffix="Months" />
                    <Details title = "Total loan exposure amount" field="loan_details.total_loan_exposure_amount" prefix="AED" isAmount />
                  </div>
                </div>

                <div className="pre-underwriting-details mt-3">
                  <div className="detail-container d-flex align-items-center">
                    <div className="title">
                      Preunderwriting status
                    </div>
                    <div className="value flex-grow-1">
                      {getPreunderwritingStatus()}
                    </div>
                  </div>
                </div>

                {
                  ([LOAN_TYPES.TOP_UP, LOAN_TYPES.RESCHEDULE].includes(data.loan_details.loan_request_type) ||
                  (data.loan_details.loan_request_type === LOAN_TYPES.NEW && data.loan_details.have_existing_loan === "Yes")
                  ) &&
                  <PreviousLoans
                    data={data}
                  />
                }

                {
                  data.other_applications.length > 0 &&
                  <div className="pre-underwriting-details" style={{ marginTop : "-1px" }}>
                    <div className="detail-container d-flex align-items-center mb-3">
                      <div className="title w-100">
                        Other Applications
                      </div>
                    </div>

                    
                    <div className="detail-container">
                      <div className="value">
                        <ul className="mb-0">
                          {
                            data.other_applications.map((other_application) => (
                              <li key={other_application.id}>
                                <Link to = {`/application/${other_application.id}`} className="value" target="_blank">
                                  {other_application.id}
                                </Link>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                }

                <div className="detail-row mt-3">
                  <div>
                    <Details title = "National ID" field="customer_details.civil_id" />
                    <Details title = "Mobile No." field="customer_details.contact_number" />
                    <Details title = "Email" field="customer_details.email" />
                  </div>
                </div>

                <div className="detail-row mt-3">
                  <Details title = "Submitted By" field="agent.name" />
                  <Details title = "Submitted At" field="submitted_at" isDate dateFormat="DD-MM-YYYY" />
                </div>

                {
                  isDataLoaded &&
                  <div className="mt-3 d-flex justify-content-end">
                    <PDFDownloadLink
                      document={
                        <UnderwritingSheet
                          data = {data}
                          underwriting_status = {getPreunderwritingStatus()}
                        />
                      }

                      fileName={`${data.id}-Underwiriting-Sheet.pdf`}
                    >
                      {({ loading }) => (
                        <button
                          disabled = {loading}
                        >
                          {loading ? "Processing" : "Download underwriting sheet"}
                        </button>
                      )}
                    </PDFDownloadLink>
                  </div>
                }
              </div>

              <div className="paper medical-details">
                <div className="paper-title text-center">
                  Medical Center Details
                </div>
                <Details
                  title = {data.sent_to_medical_center ? "Medical Center" : "Desired Medical Center"}
                  field="selected_center.name"
                  suffix = {_.get(data, "selected_center.location") ? `, ${_.get(data, "selected_center.location")}` : ""}
                />
                <Details
                  title = {data.sent_to_medical_center ? "Appointment Time" : "Desired Medical Appointment Time"}
                  field = {data.sent_to_medical_center ? "confirmed_medical_center_details.medical_appointment_time" :"medical_details.desired_medical_appointment_time"}
                  isDate
                  dateFormat="DD-MM-YYYY hh:mm A"
                />
                {
                  data.sent_to_medical_center &&
                  <Details
                    title = "MTRF sent on"
                    field="sent_to_medical_center_at"
                    isDate
                    dateFormat="DD-MM-YYYY hh:mm A"
                  />
                }

                <div className="mt-3">
                  {
                    data.sent_to_medical_center ? 
                    <button
                      onClick={downloadMtrf}
                      disabled = {isDownloadingMtrf}
                    >
                      {isDownloadingMtrf ? "Downloading . . ." : "Download MTRF"}
                    </button> :
                    <MedicalPopup
                      showSnackbar = {showSnackbar}
                      data = {data}
                      getData = {getData}
                    />
                  }
                </div>
              </div>

              {/* <div className="paper">
                <div className="paper-title">
                  Employment Details
                </div>
                {
                  _.get(data, "employment_details.occupation_type") === "Others" &&
                  <Details title = "Ocuupation Details" field="employment_details.occupation_details" />
                }
                {
                  _.get(data, "employment_details.occupation_type") === "Retired" &&
                  <Details title = "The reason for retirement was due to medical condition" field="employment_details.retired_due_to_medical_condition" />
                }
                <Details title = "Does your occupation require you to carry arms, work at height, work underground, offshore, at remote locations, handling or transporting hazardous materials or perform duties of hazardous nature" field="employment_details.require_dangerous_activity" />
                {
                  _.get(data, "employment_details.require_dangerous_activity") === "Yes" &&
                  <Details title = "Ocuupation Details" field="employment_details.dangerous_activity_details" />
                }
              </div> */}

              {
                (data.uploaded_documents && data.uploaded_documents.length > 0) &&
                <DownloadDocuments
                  title = "Download Documents"
                  documents = {data.uploaded_documents}
                  getData = {getData}
                  showSnackbar = {showSnackbar}
                />
              }

              {
                (data.medical_documents && data.medical_documents.length > 0) &&
                <DownloadDocuments
                  title = "Download Medical Report"
                  documents = {data.medical_documents}
                  getData = {getData}
                />
              }

            </div>

            <div>
              {/* <div className="paper">
                <div className="paper-title">
                  Customer Details
                </div>
                <Details title = "Gender" field="customer_details.gender" />
                <Details title = "Height" field="customer_details.height" suffix="cms" />
                <Details title = "Weight" field="customer_details.weight" suffix="kgs" />
                
              </div> */}

              <UnderwritingDetails
                showSnackbar = {showSnackbar}
                data = {data}
                getData = {getData}
              />

              <div className="paper">
                <div className="comment-box-title paper-title"> Underwriting Comment Box </div>
                <textarea
                  className="w-100"
                  rows={10}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)} 
                ></textarea>

                <div className="d-flex justify-content-end">
                  <button
                    onClick={handleSaveComment}
                    disabled = {isSavingComment}
                  >
                    {isSavingComment ? "Saving . . ." : "Save"}
                  </button>
                </div>
              </div>

              {
                data.amendment_request_received &&
                <div className="paper">
                  <div className="comment-box-title paper-title"> Amendment Request  </div>
                  {
                    data.amendment_request
                  }
                  <div className="d-flex justify-content-end mt-3">
                    <Link to={`/application/edit/${data.id}`}>
                      <button className="icon-button d-flex align-items-center justify-content-center">
                        <Edit />
                      </button>
                    </Link>
                  </div>
                </div>
              }

            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ApplicationView;