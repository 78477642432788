import {saveAs} from "file-saver"
import { useParams } from "react-router-dom";
import config from "../services/config";
import { useState } from "react";
import ApplicationService from "../services/application";
import { messageTypes } from "../constants";

const DownloadDocuments = ({ title, documents, getData, showSnackbar }) => {
  
  const {id} = useParams();

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingTitle, setDeletingTitle] = useState("");

  const download = (document) => {
    const filename = document.filename;
    const fileUrl = `${config.baseUrl}/uploads/${document.filename}`;
    const fileExtension = filename.substring(filename.lastIndexOf("."));
    const saveFileName = `${id}-${document.title}${fileExtension}`;
    saveAs(fileUrl, saveFileName);
  }

  const deleteDocument = (document) => {
    const title = document.title;
    setIsDeleting(true);
    setDeletingTitle(title);
    ApplicationService.deleteDocument(id, {title, document_field : "uploaded_documents"}).then((res) => {
      showSnackbar("Document deleted successfully !", messageTypes.SUCCESS);
      getData();
    }).catch((e) => {
      showSnackbar("Document not deleted !", messageTypes.ERROR)
    }).finally(() => {
      setDeletingTitle("")
      setIsDeleting(false)
    })
    console.log(title);
  }
  return(
    <div className="paper">
      <div className="paper-title">
        {title}
      </div>

      {
        documents.map((document, ind) =>
          <div className="d-flex align-items-center justify-content-between download-document-box" key={`document-${ind + 1}`}>
            {document.title}

            <div>

              {
                title === "Download Documents" &&
                <button
                  className="button-sm bg-danger text-white me-2"
                  onClick={() => deleteDocument(document)}
                  disabled = {isDeleting && deletingTitle === document.title}
                >
                  {(isDeleting && deletingTitle === document.title) ? "Deleting . . ." : "Delete"}
                </button>
              }
              <button className="button-sm" onClick={() => download(document)}>
                Download
              </button>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default DownloadDocuments;