import { useState } from "react";
import { messageTypes } from "../constants";

const withSnackbar = (WrappedComponent) => {
  
  return (props) => {
    const [show, setShow] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    
    const showSnackbar = (message, type = messageTypes.SUCCESS, duration = 3500) => {
      let timeout;

      if(timeout){
        clearTimeout(timeout);
      }

      setShow(true);
      setMessage(message);
      setMessageType(type);
      
      timeout = setTimeout(() => {
        setShow(false);
      }, duration)
    }


    return(
      <>
        <div className={`snackbar ${show ? "show" : ""} ${messageType}`}>
          {message}
        </div>
        <WrappedComponent {...props} showSnackbar = {showSnackbar} />
      </>
    )
  }

}

export default withSnackbar;