import axios from "axios";
import { initializeApp } from "firebase/app";
import { signInWithEmailAndPassword, getAuth, signOut } from "firebase/auth";
import config from "./config";

const baseUrl = config.baseUrl;

// const firebaseConfig = {
//   apiKey: "AIzaSyBYTL0A8SL1xJuAR9s27IvqeUCQyjqt8UM",
//   authDomain: "dhofar-credit-life.firebaseapp.com",
//   projectId: "dhofar-credit-life",
//   storageBucket: "dhofar-credit-life.appspot.com",
//   messagingSenderId: "772483572626",
//   appId: "1:772483572626:web:f3beeacfe008b66380974b",
//   measurementId: "G-1BFE9DP07L"
// };

const firebaseConfig = {
  apiKey: "AIzaSyC6eubOxrLkUWiJ6lKrEvywsH6crNn5HEc",
  authDomain: "credit-life-demo.firebaseapp.com",
  projectId: "credit-life-demo",
  storageBucket: "credit-life-demo.appspot.com",
  messagingSenderId: "271016088703",
  appId: "1:271016088703:web:7b40f30b1252cc19fa1b4a",
  measurementId: "G-MNXQLTHFXZ"
};

const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app)

const auth = {};

auth.login = async (email, password) => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(firebaseAuth, email, password).then((authRes) => {
      const userData = authRes.user;
      const {accessToken, uid} = userData;
      auth.getToken(accessToken, uid).then((tokenRes) => {
        localStorage.setItem("cl_access_token", tokenRes.data.token);
        resolve(true);
      })
    }).catch(() => {
      resolve(false)
    })
  })
}


auth.createUser = async (data) => {
  const url = `${baseUrl}/add-user`;
  const res = await axios({
    url,
    method : "POST",
    data : data
  });

  return res;
}

auth.getUsers = async (page, limit) => {
  const url = `${baseUrl}/get-users`;
  let params = {};

  if(page !== undefined && limit !== undefined){
    params = {
      page,
      limit
    }
  }
  const res = axios({
    url,
    params
  });
  return res;
}

auth.getToken = async (authToken, uid) => {
  const url = `${baseUrl}/login`;
  const res = await axios({
    url,
    method : "POST",
    data : {
      authToken,
      uid,
      role : "underwriter"
    }
  });
  return res;
}

auth.logout = () => {
  signOut(firebaseAuth);
  localStorage.removeItem("cl_access_token");
}

auth.updateUser = (id, data) => {
  const url = `${baseUrl}/update-user/${id}`;
  const res = axios({
    url,
    method : "PUT",
    data
  });
  return res;
}

export default auth;