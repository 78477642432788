import { useState } from "react";
import moment from "moment";
import ApplicationService from "../services/application";
import * as XLSX from "xlsx";
import Breadcrumbs from "../components/Breadcrumbs";
import MedicalCenters from "../data/MedicalCenters.json";
import { medicalTests } from "../constants";
import DatePicker from "react-datepicker";
import CustomDateInput from "../components/CustomDateInput";

const REPORT_TITLES = [
  "S. No.",
  "UW Name",
  "Initiation Branch",
  "CIF No.",
  "National ID",
  "Customer Name",
  "Date of Birth",
  "Gender",
  "Contact Number",
  "Email",
  "Loan Type",
  "Loan Amount",
  "Existing Loan",
  "Total Exposure",
  "Loan Tenure - Months",
  "Occupation",
  "Height (in cms)",
  "Weight (in kgs)",
  "BMI",
  "Age",
  "TAT",
  "Pre U/W",
  "Branch Submission Date",
  "Medical Book Date",
  "UW Status",
  "Remarks",
  "UW Decision Date",
  "RI Reference Number",
  "Loading",
  "Reason For Decision",
  "Decision Letter Sent"
];

const MEDICAL_TITLES = [
  "BD no.",
  "MRTF Date",
  "CIF No.",
  "National ID",
  "Name of the Borrower",
  "Date of Birth",
  "Gender",
  "Contact No",
  "Name of the center",
  "Location",
  "Contact person",
  "Contact no",
  "Date of Appointment",
  "Time of appointment",
  "1.MER",
  "2. Urine Analysis",
  "3. Lab 1",
  "4. Lab 2",
  "5. ECG",
  "6. TMT",
  "7.HIV & HCV",
  "8. Other",
  "Cost to be borne by"
]

const Report = () => {

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingMedical, setIsDownloadingMedical] = useState(false);

  const downloadReport = () => {
    setIsDownloading(true);
    const filterObj = {
      startDate : new Date(`${startDate} 00:00:00`).getTime(),
      endDate : new Date(`${endDate} 23:59:59`).getTime(),
    }
    ApplicationService.getApplications(filterObj).then((res) => {
      
      const applications = res.data.data;

      let reportData = [];

      
      applications.forEach((application) => {
        
        let pre_underwriting_status = "Non Medical"
        const bmi = application.bmi;
        if(bmi <= 17 || bmi > 36){
          pre_underwriting_status = "Medical"
        }

        const submitted_date = moment(application.submitted_at);
        
        let TAT = "NA";

        if(application.underwriting_details_sent){
          const underwriting_sent_date = moment(application.underwriting_details_sent_at);
          TAT = underwriting_sent_date.diff(submitted_date, "days");
        }

        reportData.push({
          [REPORT_TITLES[0]] : application.id,
          [REPORT_TITLES[1]] : application.underwriter?.name,
          [REPORT_TITLES[2]] : application.branch_details?.bank_branch_name,
          [REPORT_TITLES[3]] : application.customer_details?.cif_number,
          [REPORT_TITLES[4]] : application.customer_details?.civil_id,
          [REPORT_TITLES[5]] : application.customer_details?.customer_name,
          [REPORT_TITLES[6]] : moment(application.customer_details?.dob).format("DD-MMM-YYYY"),
          [REPORT_TITLES[7]] : application.customer_details?.gender.toUpperCase(),
          [REPORT_TITLES[8]] : application.customer_details?.contact_number,
          [REPORT_TITLES[9]] : application.customer_details?.email,
          [REPORT_TITLES[10]] : application.loan_details?.loan_type,
          [REPORT_TITLES[11]] : application.loan_details?.new_loan_amount,
          [REPORT_TITLES[12]] : application.loan_details?.existing_loan_amount,
          [REPORT_TITLES[13]] : application.loan_details?.total_loan_exposure_amount,
          [REPORT_TITLES[14]] : application.loan_details?.loan_tenure,
          [REPORT_TITLES[15]] : application.employment_details?.exact_nature_of_duties,
          [REPORT_TITLES[16]] : application.customer_details?.height,
          [REPORT_TITLES[17]] : application.customer_details?.weight,
          [REPORT_TITLES[18]] : application.bmi,
          [REPORT_TITLES[19]] : application.customer_details?.age,
          [REPORT_TITLES[20]] : TAT,
          [REPORT_TITLES[21]] : pre_underwriting_status,
          [REPORT_TITLES[22]] : submitted_date.format("DD-MMM-YYYY"),
          [REPORT_TITLES[23]] : "",
          [REPORT_TITLES[24]] : application.underwriting_decision?.underwriting_status,
          [REPORT_TITLES[25]] : application.underwriting_comments || "-",
          [REPORT_TITLES[26]] : application.underwriting_details_sent ? moment(application.underwriting_details_sent_at).format("DD-MMM-YYYY") : "-",
          [REPORT_TITLES[27]] : application.underwriting_decision?.reinsurer_reference_no,
          [REPORT_TITLES[28]] : application.underwriting_decision ? `${application.underwriting_decision?.underwriting_loading}%` : "-",
          [REPORT_TITLES[29]] : application.underwriting_decision?.reason_for_decision,
          [REPORT_TITLES[30]] : application.decision_letters ? application.decision_letters.length : 0,
        })
      })

      const worksheet = XLSX.utils.json_to_sheet(reportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, "Application-report.xlsx");
      setIsDownloading(false);
    })
  }

  const downloadMedicalReport = () => {
    setIsDownloadingMedical(true);
    const filterObj = {
      startDate : new Date(`${startDate} 00:00:00`).getTime(),
      endDate : new Date(`${endDate} 23:59:59`).getTime(),
    }
    ApplicationService.getApplications(filterObj).then((res) => {
      
      const applications = res.data.data;

      let reportData = [];

      
      applications.forEach((application) => {
        
        if(application.sent_to_medical_center){

          const selected_center = [...MedicalCenters].find((center) => center.code === application.confirmed_medical_center_details.medical_center);

          reportData.push({
            [MEDICAL_TITLES[0]] : application.id,
            [MEDICAL_TITLES[1]] : moment(application.sent_to_medical_center_at).format("DD-MMM-YYYY"),
            [MEDICAL_TITLES[2]] : application.customer_details?.cif_number,
            [MEDICAL_TITLES[3]] : application.customer_details?.civil_id,
            [MEDICAL_TITLES[4]] : application.customer_details?.customer_name,
            [MEDICAL_TITLES[5]] : moment(application.customer_details?.dob).format("DD-MMM-YYYY"),
            [MEDICAL_TITLES[6]] : application.customer_details?.gender.toUpperCase(),
            [MEDICAL_TITLES[7]] : application.customer_details?.contact_number,
            [MEDICAL_TITLES[8]] : selected_center.name,
            [MEDICAL_TITLES[9]] : selected_center.location,
            [MEDICAL_TITLES[10]] : selected_center.contact_person,
            [MEDICAL_TITLES[11]] : selected_center.contact_no,
            [MEDICAL_TITLES[12]] : moment(application.confirmed_medical_center_details.medical_appointment_time).format("DD-MMM-YYYY"),
            [MEDICAL_TITLES[13]] : moment(application.confirmed_medical_center_details.medical_appointment_time).format("hh:mm A"),
            [MEDICAL_TITLES[14]] : application.medical_tests?.includes(medicalTests.MER) ? "YES" : "NO",
            [MEDICAL_TITLES[15]] : application.medical_tests?.includes(medicalTests.URINE_ANALYSIS) ? "YES" : "NO",
            [MEDICAL_TITLES[16]] : application.medical_tests?.includes(medicalTests.LAB_1) ? "YES" : "NO",
            [MEDICAL_TITLES[17]] : application.medical_tests?.includes(medicalTests.LAB_2) ? "YES" : "NO",
            [MEDICAL_TITLES[18]] : application.medical_tests?.includes(medicalTests.ECG) ? "YES" : "NO",
            [MEDICAL_TITLES[19]] : application.medical_tests?.includes(medicalTests.TMT) ? "YES" : "NO",
            [MEDICAL_TITLES[20]] : application.medical_tests?.includes(medicalTests.HIV) ? "YES" : "NO",
            [MEDICAL_TITLES[21]] : application.confirmed_medical_center_details.medical_test_additional_information,
            [MEDICAL_TITLES[22]] : "Company"
          })
        }

      })

      const worksheet = XLSX.utils.json_to_sheet(reportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "MTRF Tracker");
      XLSX.writeFile(workbook, "Application-medical-report.xlsx");
      setIsDownloadingMedical(false);
    })
  }

  return(
    <div className="site-container report-container">

      {/* <Breadcrumbs
        links = {[
          { title : "Home", to : "/" },
          { title : "Report" }
        ]}
        
      /> */}

      <div className="home-container">

        <div className="paper with-search d-flex justify-content-between align-items-center">

            <div className="page-title">REPORT</div>
            
                <div className="grid-row">

                  <div className="input-container">
                    <label> Start Date </label>
                    
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd-MM-yyyy"
                      customInput={<CustomDateInput />}
                      dropdownMode="select"
                      placeholderText="DD-MM-YYYY"
                    />
                  </div>

                  <div className="input-container">
                    <label> End Date </label>
                    
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd-MM-yyyy"
                      customInput={<CustomDateInput />}
                      dropdownMode="select"
                      placeholderText="DD-MM-YYYY"
                    />
                  </div>

                  <div className="d-flex align-items-end">
                    <button
                      disabled = {
                        !moment(startDate).isValid() ||
                        !moment(endDate).isValid() ||
                        isDownloading
                      }
                      onClick={downloadReport}
                    >
                      {isDownloading ? "Downloading . . ." : "Download Report"}
                    </button>
                    <button
                      disabled = {
                        !moment(startDate).isValid() ||
                        !moment(endDate).isValid() ||
                        isDownloadingMedical
                      }
                      onClick={downloadMedicalReport}
                      className="ms-3"
                    >
                      {isDownloadingMedical ? "Downloading . . ." : "Download Medical Report"}
                    </button>
                  </div>

                </div>
           
        </div>

      </div>

    </div>
  )
}

export default Report;