import { useState } from "react";
import { ReactComponent as Menu } from "../static/icons/menu-icon.svg";
import { ReactComponent as Cross } from "../static/icons/cross-icon.svg";
//import { ReactComponent as Applications } from "../static/icons/applications-icon.svg";
//import { ReactComponent as Reports } from "../static/icons/reports-icon.svg";
//import { ReactComponent as Users } from "../static/icons/users-icon.svg";
//import { ReactComponent as Logout } from "../static/icons/logout-icon.svg";
import { ReactComponent as Applications } from "../static/icons/applications-icon.svg";
import { ReactComponent as Logout } from "../static/icons/log-out-outline.svg";
import { ReactComponent as Reports } from "../static/icons/reports-icon-outline.svg";
import { ReactComponent as User } from "../static/icons/person-circle-outline.svg";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { messageTypes } from "../constants";
import AuthService from "../services/auth";
import Logo from "../static/images/axss-logo.png"

const Header = ({ showSnackbar }) => {

  const [showSidebar, setShowSidebar] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  axios.interceptors.request.use(request => {
    const token = localStorage.getItem("cl_access_token");
    request.headers["Authorization"] = token;
    return request;
  })

  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if(error.response.status === 401){
      showSnackbar("Session expired ! Please login again", messageTypes.ERROR)
      setTimeout(() => {
        AuthService.logout();
        navigate("/login");
      }, 3500)
    }
    return Promise.reject(error)
  })

  const isAgentActive = location.pathname.includes('/agent');
  const isApplicationActive = location.pathname.includes('/application') ;
  const isReportActive = location.pathname.includes('/report');

  return(
    <>
    <header className={`fixed-top ${location.pathname === "/login" ? "d-none" : "d-flex"} align-items-center justify-content-between`}>
        {/*<header className={`fixed-top align-items-center ${location.pathname === "/login" ? "d-none" : "d-flex"}`}> */}
        {/*
          showSidebar ?
          <Cross
            className="menu-icon"
            height={28}
            onClick = {() => setShowSidebar(false)}
          /> :
          <Menu
            className="menu-icon"
            height={28}
            onClick = {() => setShowSidebar(true)}
          />
    */ }
        <div className="d-flex align-items-center justify-content-between">
           
              <div><img src={Logo} alt="AXSS Portal" /></div>
              <div className="d-flex align-items-center" style={{ marginLeft: 40 }}>                
                
                <NavLink to="/" className={`nav-tab d-flex align-items-center justify-content-center ${isApplicationActive ? 'active' : ''}`}>
                  Applications
                </NavLink>    
                {/* <NavLink to="/agents" className={`nav-tab d-flex align-items-center justify-content-center ${isAgentActive ? 'active' : ''}`}>
                  Agents
                </NavLink> */}
                <NavLink to="/report" className={`nav-tab d-flex align-items-center justify-content-center ${isReportActive ? 'active' : ''}`}>
                  Report
                </NavLink>

              </div>
         
        </div>
        <div className="d-flex align-items-center">
        <div className="d-flex justify-content-end align-items-end">
              <p className="user-name"><User className="user-icon" />UNDERWRITER</p>
        </div>                   
          <div className="d-flex align-items-center justify-content-end nav-icons">
            {/*<NavLink to="/" className="sidebar-link d-flex align-items-center">
                  <HomeIcon className="icon home-icon" />
                  </NavLink>*/}
            {/* <NavLink to="/settings" className="sidebar-link d-flex align-items-center">
              <Settings className="icon settings-icon" />
            </NavLink> */}
            {/*<NavLink to="/help" className="sidebar-link d-flex align-items-center">
                  <Help className="icon help-icon" />
                </NavLink>*/}
            <NavLink to="/login" className="sidebar-link d-flex align-items-center" onClick={AuthService.logout}>
              <Logout className="icon logout-icon" />
            </NavLink>
          </div>          
        </div>
      </header>

   {/*    <header className={`fixed-top align-items-center ${location.pathname === "/login" ? "d-none" : "d-flex"}`}>
        {
          showSidebar ?
          <Cross
            className="menu-icon"
            height={28}
            onClick = {() => setShowSidebar(false)}
          /> :
          <Menu
            className="menu-icon"
            height={28}
            onClick = {() => setShowSidebar(true)}
          />
        }
      </header> */}
     
     
     
      {/* SIDEBAR */}
      <div className={`sidebar-container ${showSidebar ? "show" : ""}`} onClick = {() => setShowSidebar(false)}>
       {/*  <div className="sidebar">
          <NavLink to="/" className="sidebar-link d-flex align-items-center">
            <Applications className="icon" />
            Applications
          </NavLink>
          <NavLink to="/report" className="sidebar-link d-flex align-items-center">
            <Reports className="icon report-icon" />
            Report
          </NavLink>
          <NavLink to="/agents" className="sidebar-link d-flex align-items-center">
            <Users className="icon" />
            Agents
          </NavLink>
          <NavLink to="/login" className="sidebar-link d-flex align-items-center" onClick={AuthService.logout}>
            <Logout className="icon" />
            Logout
          </NavLink>
        </div> */}
      </div>
    </>
  )
}

export default Header;