import { forwardRef } from "react";
import { ReactComponent as Calendar } from "../static/icons/calendar.svg";

const CustomDateInput = forwardRef(({ value, onClick, placeholder }, ref) => {
  return(
    <div className="d-flex date-input-container" onClick={onClick} ref={ref}>
      <div className="d-flex align-items-center value-container flex-grow-1">
        {value || placeholder}
      </div>
      <div className="icon-contaier d-flex align-items-center justify-content-center">
        <Calendar />
      </div>
    </div>
  )
});

export default CustomDateInput;