import axios from "axios";
import config from "./config";

const baseUrl = config.baseUrl;

const application = {};

application.create = async (data) => {
  const url = `${baseUrl}/create-application`;
  const res = axios({
    url,
    method : "POST",
    data : data
  });

  return res;
}

application.getApplications = async (page, limit, search) => {
  const url = `${baseUrl}/get-applications`;

  let params = {};

  if(page !== undefined && limit !== undefined){
    params = {
      page,
      limit
    }
  }

  if(search){
    params.search = search
  }
  const res = axios({
    url,
    params
  });
  return res;
}

application.getApplication = async (id) => {
  const url = `${baseUrl}/get-application/${id}`;
  const res = axios({ url });
  return res;
}

application.updateApplication = async (id, data, route = "") => {
  const url = `${baseUrl}/application${route}/${id}`;
  const res = axios({
    url,
    method : "PUT",
    data
  });
  return res;
}

application.deleteDocument = async (id, data) => {
  const url = `${baseUrl}/delete-document/${id}`;
  const res = axios({
    url,
    method : "POST",
    data
  });
  return res;
}

application.sendMTRF = async (id, data) => {
  return application.updateApplication(id,data, "/send-mtrf");
}

application.savePremiumDetails = async (id, data) => {
  return application.updateApplication(id,data, "/save-premium-details");
}

application.approveQuote = async (id) => {
  const url = `${baseUrl}/application/approve-quote/${id}`;
  const res = axios({
    url,
    method : "POST"
  });
  return res;
}
export default application;