import { useState } from "react";
import validator from "validator"
import { messageTypes } from "../constants";
import AuthService from "../services/auth";
import { useNavigate } from "react-router-dom";

const Login = ({ showSnackbar }) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setLogginIn] = useState(false);

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    setLogginIn(true);
    AuthService.login(email,password).then((loggedIn) => {
      setLogginIn(false);
      if(loggedIn){
        navigate("/")
      }
      else{
        showSnackbar("Invalid email or password", messageTypes.ERROR)
      }
    })
  }

  const isDataFilled = () => {
    if(
      validator.isEmail(email) &&
      password.length >= 6
    ){
      return true;
    }

    return false;
  }
  return(
    <div className="min-vh-100 d-flex align-items-center justify-content-center py-5">
      <form onSubmit={handleLogin} className = "login-form">
        <div className="title text-center"> Login </div>
        <label> Email </label>
        <input
          type="text"
          className="w-100"
          value={email}
          onChange = {(e) => setEmail(e.target.value)}
        />
        <label> Password </label>
        <input
          type="password"
          className="w-100 mb-0"
          value={password}
          onChange = {(e) => setPassword(e.target.value)}
        />
        <button
          className="w-100"
          disabled = {!isDataFilled() || isLoggingIn}
        >
          {isLoggingIn ? "Login . . ." : "Login"}
        </button>
      </form>
    </div>
  )
}

export default Login;